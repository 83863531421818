/*global jQuery*/
(function (window, $) {
	var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

	/**
	 * Returns a Form element, attached to the document body
	 * with a CSRF token input already populated
	 *
	 * @param action string The action url
	 * @param method string The method to use
	 * @param inputs Object	Additional key value pairs to add as hidden fields
	 */
	var makeForm = function(action, method, inputs) {
		// Create a new form element
		var form = document.createElement('form');
		form.setAttribute('action', action);
		form.setAttribute('method', 'POST');
		form.setAttribute('style', 'display: none;');

		// Hidden _method input
		var methodInput = document.createElement('input');
		methodInput.setAttribute('type', 'hidden');
		methodInput.setAttribute('name', '_method');
		methodInput.setAttribute('value', method);

		// Hidden _token input
		var token = document.createElement('input');
		token.setAttribute('type', 'hidden');
		token.setAttribute('name', '_token');
		token.setAttribute('value', CSRF_TOKEN);

		form.appendChild(methodInput);
		form.appendChild(token);

		if (inputs) {
			for (var prop in inputs) {
				if (inputs.hasOwnProperty(prop)) {
					var input = document.createElement('input');
					input.setAttribute('type', 'hidden');
					input.setAttribute('name', prop);
					input.setAttribute('value', inputs[prop]);
				}

				form.appendChild(input);
			}
		}

		document.body.appendChild(form);

		return form;
	};

	$('.datepicker').datepicker({
		dateFormat: 'yy-mm-dd'
	});

	// Setup CSRF for AJAX requests
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': CSRF_TOKEN
		}
	});

	// Tag a link with .delete-link to make it submit a DELETE form to
	// the URL in it's href attribute
	$('a.delete-link').on('click', function() {

		if (confirm('Are you sure you want to delete this?')) {
			var form = makeForm(this.getAttribute('href'), 'DELETE');
			form.submit();
		}

		return false;
	});

	$('a.post-link').on('click', function() {
		// Grab the data from the data-input attribute,
		// attach to the form and send it along
		var form = makeForm(
			this.getAttribute('href'),
			this.getAttribute('data-method') || 'POST',
			JSON.parse(this.getAttribute('data-input') || '')
		);

		form.submit();

		return false;
	});

	/* Logout confirmation */
	$('a.logout-link').on('click', function () {
		return confirm('Are you sure you want to log out?');
	});

	$('.company-state-checkbox').on('change', function() {
		var extraForm = $(this).siblings('.company-state-extra-form');
		if (this.checked) {
			extraForm.slideDown();
		} else {
			extraForm.slideUp();
		}
	}).trigger('change');

	$('#license-filters select').bind('change', changeLicenseFilters);
	changeLicenseFilters();

	$('.clear-filters').bind('click', function() {
		$(this).parent().find('select').val('').trigger('change');
	});

	$('.contingent-parent input[type=radio]')
		.bind('click', changeContingentRadio)
		.each(changeContingentRadio);

	$('#date_filed').bind('change', function() {
		if($(this).val() && !$('#date_renewal_filed').val()) {
			$('#date_renewal_filed').val($(this).val());
		}
	});

	$('#date_granted').bind('change', function() {
		if($(this).val() && !$('#date_renewal_granted').val()) {
			$('#date_renewal_granted').val($(this).val());
		}
	});

	setupMobileMenu();

	setTimeout(updateProgressBar, 2000);

	function updateProgressBar() {
		var perc = $('.progressbar-fill').attr('data-percent');
		var newclass = perc == 100 ? 'complete' : (perc > 25 ? 'inprogress' : 'incomplete');
		$('.progressbar-fill').removeClass('incomplete inprogress complete').addClass(newclass);
		$('.progressbar-fill').css('width', perc + '%');
	}


	function changeLicenseFilters() {
		var state = $('#license-filters #state_id').val();
		var industry_id = $('#license-filters #industry_id').val();
		var reminder_type_id = $('#license-filters #reminder_type_id').val();
		var rows = $('#company-licenses tbody tr');

		$(rows).hide();

		if(state) {
			rows = rows.filter('[data-state="' + state + '"]');
		}

		if(industry_id) {
			rows = rows.filter('[data-industry-id="' + industry_id + '"]');
		}

		if(reminder_type_id) {
			rows = rows.filter('[data-reminder-type="' + reminder_type_id + '"]');
		}

		rows.show();
	}

	function changeContingentRadio() {
		var parent = $(this).parents('.contingent-parent');
		var val = $('input[type=radio]:checked', parent).val();

		// Hide all children with a data-value attribute that doesn't match
		//	note the first [data-value] is needed to check the element has the attribute
		$('[data-value][data-value!="' + val + '"]', parent).slideUp();
		// Show all children with a data-value attribute that matches
		$('[data-value="' + val + '"]', parent).slideDown();
	}

	function setupMobileMenu() {

		/** Primary nav scripting */
		$('#menu-icon').bind('click', function (e) {
			var page = $('html');

			e.preventDefault();

			// Already open, close it
			if (page.hasClass('nav-open')) {
				page.removeClass('nav-open');

			// Open the nav but create an event listener so if there's a click on the main body we'll close it again
			} else {
				e.stopPropagation();
				page.one('click', function () {
					page.removeClass('nav-open');
				}).addClass('nav-open');
			}
		});

		// Prevent clicking on a #primary-nav element from bubbling up to the listeners on <html>
		$('.nav-main').bind('click', function (e) {
			e.stopPropagation();
		});

		$('.nav-main ul li').one('mouseover', function() {
			$(this).addClass('open');
			$('a', this).unbind('click');
		});

		$('.nav-main ul li > a').one('click', function() {
			// This should only apply on a mobile device where the menu isn't opened until click;
			//	 On desktop, the open class has already been added on mouseover
			if(!$(this).parent().hasClass('open')) {
				$(this).parent().addClass('open');
				return false;
			}
		});

	}
} (this, jQuery));
